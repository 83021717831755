const interne = "Interne";
const offentlige = "Offentlige";
const filressurser = "Filressurser";

const interneLinker = [
  {
    tittel: "Permanente skiltforskrifter i Qlik fra 01.12.2022",
    link: "https://bym-qlik.bymoslo.no/oslofelles/sense/app/53559c32-03c9-4670-8aa9-d7581543ad64/sheet/e6e24419-18ba-4207-8777-a72c01341856/state/analysis",
    kategori: interne,
  },
  {
    tittel: "INE-PAX fra 08.12.2022",
    link: "https://pax.ine.oslo.kommune.no/RDWeb/webclient/",
    kategori: interne,
  },

  {
    tittel: "Inntauingsløsning fra 01.12.2022",
    link: "https://inntauing.bymoslo.no/login",
    kategori: interne,
  },
  {
    tittel: "Qlik Sense Hub",
    link: "https://bym-qlik.bymoslo.no/oslofelles/hub/stream/6c369999-678a-4953-a4e9-aef266306454",
    kategori: interne,
  },
  {
    tittel: "Plan og bygningsetatens eiendomsregister",
    link: "http://10.192.10.166/webmatrikkel/Matrikkel.aspx?knr=301",
    kategori: interne,
  },
  {
    tittel: "Maintenance automatfeil/ladestolpefeil",
    link: "https://oslokommune.myview.cloud",
    kategori: interne,
  },
];

const offentligeLinker = [
  { tittel: "Lovdata", link: "https://lovdata.no/", kategori: offentlige },
  {
    tittel: "Google Maps",
    link: "https://www.google.no/maps/",
    kategori: offentlige,
  },
  {
    tittel: "Infotorg",
    link: "https://www.infotorg.no/",
    kategori: offentlige,
  },
  {
    tittel: "Bil i Oslo",
    link: "https://client-prod.bilioslo.bym.oslo.kommune.no/",
    kategori: interne,
  },
  {
    tittel: "Piggdekkoblat-Admin",
    link: "https://piggav.bymoslo.no/login",
    kategori: interne,
  },
  {
    tittel: "EasyPark",
    link: "https://dashboard.easypark.net/",
    kategori: interne,
  },
  {
    tittel: "Prinsippavgjørelser Parkeringsklagenemnda",
    link: "http://pklagenemnda.no/prinsippavgjorelser/",
    kategori: offentlige,
  },
  {
    tittel: "Kart over betalingsautomater",
    link: "http://www.arcgis.com/apps/webappviewer/index.html?id=8e6ab845c33342d1811259b0abc49a34&extent=593512.5204,6641105.451,601640.5367,6645010.7088,25832",
    kategori: offentlige,
  },
  {
    tittel: "Cale automat",
    link: "https://weboffice2.caleaccess.com/Cwo2/UserLogin.aspx",
    kategori: interne,
  },
  {
    tittel: "Enhetsregistret - Brønnøysundregistret",
    link: "https://w2.brreg.no/enhet/sok/",
    kategori: offentlige,
  },
  {
    tittel: "Svar Ut",
    link: "https://svarut.ks.no/",
    kategori: offentlige,
  },
];

const filressurserLinker = [
  {
    tittel: "Bilder miljøgebyr/Ureg",
    link: `file://///oslofelles.oslo.kommune.no/felles-is/TET_BildeArkiv`,
    kategori: filressurser,
  },
  {
    tittel: "Erfaringsarkiv - parkeringsrett",
    link: `\\\\FIL329528\\Felles-IS$\\BYM-Felles-21\\Juridisk avdeling\\Felles - parkering, miljø og HC\\Erfaringsarkiv`,
    kategori: filressurser,
  },
  {
    tittel: "VS og varsel lapper",
    link: `file://///oslofelles.oslo.kommune.no/felles-is-bym/Felles/Tverrfagelig/VS og varsel lapper`,
    kategori: filressurser,
  },
  {
    tittel: "P-meldinger",
    link: `file://///oslofelles.oslo.kommune.no/felles-is-bym/Felles/Tverrfagelig/Bilder og P-meldinger/P-meldinger`,
    kategori: filressurser,
  },
];

export { interneLinker, offentligeLinker, filressurserLinker };
